import React from "react";
import "./Shape.css";

function Shape(props) {
  const { src, className } = props;

  return <img className={`shape-1 ${className || ""}`} alt='asc' src={src} />;
}

export default Shape;
