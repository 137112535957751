import "./App.css";
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import FormularzEkranDotykowy1920x180 from "./components/FormularzEkranDotykowy1920x180";
import { Redirect } from "react-router-dom";

function App() {
  const reload = () => window.location.reload();
  return (
    <Router>
      <Switch>
        <Route path="/scan.html" render={reload} />
        <Route
          exact
          path="/"
          render={() => (
            <Redirect
              to={{
                pathname: "/scan.html",
              }}
            />
          )}
        />
        <Route exact path="/form">
          <FormularzEkranDotykowy1920x180
            {...formularzEkranDotykowy1920x180Data}
          />
        </Route>
        <Route exact path="/form/tablet">
          <FormularzEkranDotykowy1920x180 {...FormWithCloseData} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

const shape1Data = {
  src:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/624e69fba199e50290c48687/img/03checkin-shape-993F343C-87AF-4FE9-A4CB-4FCDE6C52444@2x.png",
};

const shape2Data = {
  src:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/624e69fba199e50290c48687/img/04uwaga-shape-CC69CE40-3D71-4139-9884-6ED38B46B8B0@2x.png",
  className: "shape-2",
};

const formularzEkranDotykowy1920x180Data = {
  path2:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/624e69fba199e50290c48687/img/03checkin-path-3E9D4318-87B2-4CA4-8DE4-1A308AA2689E@2x.png",
  group:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/624e69fba199e50290c48687/img/02skan-path-657FC206-9CF5-43B7-9342-A321C934F8D5@2x.png",
  path3:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/624e69fba199e50290c48687/img/01witamy-path-CE0B8D51-81C8-4018-A9A0-3EF5DF61FA96@2x.png",
  view1:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/6260747c95bd0d454b070d24/img/formularzekrandotykowy1920x180-path-AFF575D0-3659-4AC6-8FF3-FFA965BB7BDE@2x.png",
  formularzRejestracy: "Formularz rejestracyjny",
  profesja: "Profesja *",
  path22:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/6260747c95bd0d454b070d24/img/formularzekrandotykowy1920x180-path-2-00C6929D-8E46-4CCE-9D45-654C964BB021@2x.png",
  wybierzZListy: "Wybierz z listy…",
  imi: "Imię *",
  nazwisko: "Nazwisko *",
  nazwaFirmy: "Nazwa firmy",
  eMail: "E-mail *",
  telefon: "Telefon *",
  text2: "+48",
  adres: "Adres",
  kodPocztowy: "Kod pocztowy",
  miasto: "Miasto *",
  text1: "__-___",
  owiadczenieZgodni: (
    <>
      Informacja
      <br />
      <br />
      Podstawę przetwarzania danych osobowych zawartych w tym formularzu stanowi
      przepis art. 6 ust. 1 lit. f RODO tj. niezbędność przetwarzania do celów
      wynikających z prawnie uzasadnionych interesów realizowanych przez
      Administratora. Przetwarzamy Państwa dane osobowe w celu prowadzenia
      marketingu bezpośredniego promującego nasze produkty i usługi. Aby
      prowadzić marketing z wykorzystaniem Państwa adresu e-mail lub numeru
      telefonu musimy uzyskać od Państwa zgodę na kanał komunikacji zgodnie z
      ustawą o świadczeniu usług drogą elektroniczną lub ustawą – Prawo
      telekomunikacyjne.
      <br />
      <br />
      Wyrażam zgodę na otrzymywanie informacji handlowych wysyłanych przez Purmo
      Group Poland Sp. z o.o. na:
    </>
  ),
  mjAdresEMail: "mój adres e-mail",
  mjTelefon: "mój telefon",
  line:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/6260747c95bd0d454b070d24/img/formularzekrandotykowy1920x180-line-4E74918B-A32C-40FA-AFBB-FA3F74D9B6B0.png",
  akceptujPowyszT: "Akceptuję powyższą treść oświadczenia *",
  wylij: "WYŚLIJ",
  lineCopy:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/6260747c95bd0d454b070d24/img/formularzekrandotykowy1920x180-line-4E74918B-A32C-40FA-AFBB-FA3F74D9B6B0.png",
  shape1Props: shape1Data,
  shape2Props: shape2Data,
  isOpen: false,
};

const FormWithCloseData = {
  path2:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/624e69fba199e50290c48687/img/03checkin-path-3E9D4318-87B2-4CA4-8DE4-1A308AA2689E@2x.png",
  group:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/624e69fba199e50290c48687/img/02skan-path-657FC206-9CF5-43B7-9342-A321C934F8D5@2x.png",
  path3:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/624e69fba199e50290c48687/img/01witamy-path-CE0B8D51-81C8-4018-A9A0-3EF5DF61FA96@2x.png",
  view1:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/6260747c95bd0d454b070d24/img/formularzekrandotykowy1920x180-path-AFF575D0-3659-4AC6-8FF3-FFA965BB7BDE@2x.png",
  formularzRejestracy: "Formularz rejestracyjny",
  profesja: "Profesja *",
  path22:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/6260747c95bd0d454b070d24/img/formularzekrandotykowy1920x180-path-2-00C6929D-8E46-4CCE-9D45-654C964BB021@2x.png",
  wybierzZListy: "Wybierz z listy…",
  imi: "Imię *",
  nazwisko: "Nazwisko *",
  nazwaFirmy: "Nazwa firmy",
  eMail: "E-mail *",
  telefon: "Telefon *",
  text2: "+48",
  adres: "Adres",
  kodPocztowy: "Kod pocztowy",
  miasto: "Miasto *",
  text1: "__-___",
  owiadczenieZgodni: (
    <>
      Informacja
      <br />
      <br />
      Podstawę przetwarzania danych osobowych zawartych w tym formularzu stanowi
      przepis art. 6 ust. 1 lit. f RODO tj. niezbędność przetwarzania do celów
      wynikających z prawnie uzasadnionych interesów realizowanych przez
      Administratora. Przetwarzamy Państwa dane osobowe w celu prowadzenia
      marketingu bezpośredniego promującego nasze produkty i usługi. Aby
      prowadzić marketing z wykorzystaniem Państwa adresu e-mail lub numeru
      telefonu musimy uzyskać od Państwa zgodę na kanał komunikacji zgodnie z
      ustawą o świadczeniu usług drogą elektroniczną lub ustawą – Prawo
      telekomunikacyjne.
      <br />
      <br />
      Wyrażam zgodę na otrzymywanie informacji handlowych wysyłanych przez Purmo
      Group Poland Sp. z o.o. na:
    </>
  ),
  mjAdresEMail: "mój adres e-mail",
  mjTelefon: "mój telefon",
  line:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/6260747c95bd0d454b070d24/img/formularzekrandotykowy1920x180-line-4E74918B-A32C-40FA-AFBB-FA3F74D9B6B0.png",
  akceptujPowyszT: "Akceptuję powyższą treść oświadczenia *",
  wylij: "WYŚLIJ",
  lineCopy:
    "https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/6260747c95bd0d454b070d24/img/formularzekrandotykowy1920x180-line-4E74918B-A32C-40FA-AFBB-FA3F74D9B6B0.png",
  shape1Props: shape1Data,
  shape2Props: shape2Data,
  isOpen: true,
};
