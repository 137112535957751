import React from "react";
import Shape from "../Shape";

// Import styles
import "./FormularzEkranDotykowy1920x180.css";

// Impoer components
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";

// import axios from "axios";
function FormularzEkranDotykowy1920x180(props) {
  const {
    path2,
    group,
    path3,
    view1,
    formularzRejestracy,
    profesja,
    imi,
    nazwisko,
    nazwaFirmy,
    eMail,
    telefon,
    text2,
    adres,
    kodPocztowy,
    miasto,
    owiadczenieZgodni,
    mjAdresEMail,
    mjTelefon,
    line,
    akceptujPowyszT,
    wylij,
    lineCopy,
    shape1Props,
    shape2Props,
    isOpen,
  } = props;

  const { register, handleSubmit, reset } = useForm();
  const history = useHistory();

  const postURL = "/form";
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  async function onSubmit(values) {
    return new Promise((resolve) => {
      fetch(postURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => {
          if (response.ok) {
            reset();
            return isOpen ? history.push("/") : scrollToTop();
          }
          throw new Error("Something went wrong");
        })
        .then(() => {
          isOpen ? history.push("/") : scrollToTop();
          reset();
        })
        .catch((error) => {
          console.log("error");
        });
    });
  }
  return (
    <div className="container-center-horizontal">
      <form
        className="formularzekrandotykowy1920x180 screen"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div className="flex-col">
          <div className="flex-col-1">
            <div className="flex-row">
              <div className="purmo_logo_horizontal_rgb">
                <div className="group">
                  <img
                    alt="a"
                    className="shape"
                    src="https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/624e69fba199e50290c48687/img/02skan-shape-E6BE0FC0-1595-4FCC-918F-EE12ACEB293A@2x.png"
                  />
                  <img
                    alt="ab"
                    className="path"
                    src="https://anima-uploads.s3.amazonaws.com/projects/62361beca68e67aa2dc7f3c5/releases/624e69fba199e50290c48687/img/04uwaga-path-DEADABB0-CCBC-4069-BD8F-39D2B04458EB@2x.png"
                  />
                  <Shape src={shape1Props.src} />
                  <img className="path-1" src={path2} alt="a" />
                  <Shape
                    src={shape2Props.src}
                    className={shape2Props.className}
                  />
                </div>
                <div
                  className="group-1"
                  style={{ backgroundImage: `url(${group})` }}
                >
                  <img className="path-2" src={path3} alt="a" />
                </div>
              </div>
              {isOpen ? (
                <Link to="/">
                  <div className="overlap-group">
                    <div
                      className="x"
                      style={{ backgroundImage: `url(${view1})` }}
                    ></div>
                  </div>
                </Link>
              ) : null}
            </div>
            <div className="formularz-rejestracy">{formularzRejestracy}</div>
          </div>
          <div className="profesja unineue-bold-daintree-20px">{profesja}</div>
          <select
            className="overlap-group6"
            name="profession"
            ref={register({ required: true })}
          >
            <option value="" selected disabled hidden>
              Wybierz z listy...
            </option>
            <option value="instalator">Instalator</option>
            <option value="projektant">Projektant</option>
            <option value="sprzedawca">Sprzedawca</option>
            <option value="dystrybutor">Dystrybutor</option>
            <option value="student">Student</option>
            <option value="inne">Inne</option>
          </select>
          <div className="flex-row-1 unineue-bold-daintree-20px">
            <div className="imi">{imi}</div>
            <div className="nazwisko">{nazwisko}</div>
          </div>
          <div className="flex-col-item">
            <input
              className="rectangle-copy border-1px-daintree"
              defaultValue=""
              name="first_name"
              ref={register({ required: true, autoComplete: "off" })}
            ></input>
            <input
              className="rectangle-copy-2 border-1px-daintree"
              defaultValue=""
              name="last_name"
              ref={register({ required: true, autoComplete: "off" })}
            ></input>
          </div>
          <div className="flex-col-item-1 unineue-bold-daintree-20px">
            {nazwaFirmy}
          </div>
          <input
            className="rectangle-copy-1 border-1px-daintree"
            name="company_name"
            defaultValue=""
            ref={register()}
          />
          <div className="flex-row-2 unineue-bold-daintree-20px">
            <div className="e-mail">{eMail}</div>
            <div className="telefon">{telefon}</div>
          </div>
          <div className="flex-col-item">
            <input
              className="rectangle-copy border-1px-daintree"
              name="mail"
              autoComplete="off"
              placeholder=""
              ref={register({
                required: true,
                pattern: /^\S+@\S+$/i,
                autoComplete: "off",
              })}
            />
            <div className="overlap-group5 border-1px-daintree">
              <div className="text unineue-bold-daintree-24px">{text2}</div>
              <input
                className="phone-input"
                placeholder=""
                name="telephone"
                ref={register({ required: true, autoComplete: "off" })}
              />
            </div>
          </div>
          <div className="flex-col-item-1 unineue-bold-daintree-20px">
            {adres}
          </div>
          <input
            className="rectangle-copy-1 border-1px-daintree"
            name="client_address"
            defaultValue=""
            ref={register()}
          />
          <div className="flex-row-3 unineue-bold-daintree-20px">
            <div className="kod-pocztowy">{kodPocztowy}</div>
            <div className="miasto">{miasto}</div>
          </div>
          <div className="flex-col-item">
            <input
              className="overlap-group4 border-1px-daintree"
              name="zipcode"
              placeholder="__-___"
              ref={register({ required: false })}
            />
            <input
              className="rectangle-copy-8 border-1px-daintree"
              name="city"
              placeholder=""
              ref={register({ required: true })}
            />
          </div>
          <div className="flex-col-2">
            <div className="owiadczenie-zgodni unineue-bold-daintree-32px">
              {owiadczenieZgodni}
            </div>
            <div className="group-container">
              <div className="group-2">
                <input
                  type="checkbox"
                  className="rectangle border-1px-daintree"
                  name="marketing_telephone"
                  ref={register()}
                />
                <div className="mj-adres-e-mail unineue-bold-daintree-24px">
                  {mjAdresEMail}
                </div>
              </div>
              <div className="group-3">
                <input
                  type="checkbox"
                  className="rectangle border-1px-daintree"
                  name="marketing_email"
                  ref={register()}
                />
                <div className="mj-telefon unineue-bold-daintree-24px">
                  {mjTelefon}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex-col-2"
          style={{ minHeight: "150px", paddingTop: "70px", margin: "auto" }}
        ></div>
        <div className="flex-row-4">
          <input
            type="checkbox"
            className="overlap-group1 border-1px-daintree"
            name="terms"
            value={true}
            ref={register({ required: true })}
          />
          <div className="akceptuj-powysz-t unineue-bold-daintree-24px">
            {akceptujPowyszT}
          </div>
          <button type="submit" className="overlap-group3">
            <h1 className="wylij">{wylij}</h1>
          </button>
        </div>
        <div className="flex-col-3">
          <img className="line-copy" src={lineCopy} alt="a" />
          <div className="klauzula-informacyjn unineue-bold-daintree-32px">
            Klauzula informacyjna:
            <br />
            <br />
            Zgodnie z art. 13 ust. 1 i ust. 2rozporządzenia o ochronie danych
            osobowych z dnia 27 kwietnia 2016 r. informujemy, iż:
            <br />
            1) Administratorem Pani/Pana danych osobowych jest Purmo Group
            Poland Sp. z o.o., z siedzibą w Rybniku,
            <br />
            44-203 Rybnik, ul. Przemysłowa 11, NIP 642 000 46 63.
            <br />
            2) W przypadku zapytań lub w celu udzielenia dodatkowych informacji
            prosimy o kontakt z naszym Inspektorem Ochrony Danych:
            iod@purmogroup.com
            <br />
            3) Pani/Pana dane osobowe przetwarzane będą w celach marketingowych
            na podstawie art. 6 ust. 1 lit. f RODO
            <br />
            4) Odbiorcą Pani/Pana danych osobowych są pracownicy biurowi Purmo
            Group Poland Sp. z o.o. posiadający stosowne upoważnienia oraz
            pracownicy firm działających na zlecenie Purmo Group Poland Sp. zoo
            w celu realizacji celów marketingowych z zachowaniem tych samych
            zasad ochrony danych osobowych potwierdzonych umową.
            <br />
            5) Pani/Pana dane osobowe przetwarzamy do czasu zakończenia działań
            marketingowych przez Administratora albo do czasu wniesienia
            sprzeciwu, czyli okazania nam w dowolny sposób, że nie chcą Państwo
            pozostawać z nami w kontakcie ani otrzymywać informacji o
            podejmowanych przez nas działaniach
            <br />
            6) Posiada Pani/Pan prawo dostępu do treści swoich danych oraz prawo
            ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do
            przenoszenia danych, prawo wniesienia sprzeciwu.
            <br />
            7) Ma Pan/Pani prawo wniesienia skargi do organu nadzorczego gdy
            uzna Pani/Pan, iż przetwarzanie danych osobowych Pani/Pana
            dotyczących narusza przepisy ogólnego rozporządzenia o ochronie
            danych osobowych z dnia 27 kwietnia 2016 roku.
            <br />
            8) Podanie przez Pana/Panią danych osobowych nie jest wymogiem
            ustawowym, jest dobrowolne, ale może być niezbędne np. do:
            uczestnictwa w szkoleniach, otrzymywania promocyjnych ofert oraz
            udziału w konkursach.
            <br />
            9) Pani/Pana dane nie będą profilowane automatycznie.
            <br />
            10) Pani/Pana dane osobowe nie będą przekazywane do państw trzecich
            tj. poza Europejski Obszar Gospodarczy
          </div>
        </div>
      </form>
    </div>
  );
}

export default FormularzEkranDotykowy1920x180;
